/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.Rails = require("@rails/ujs")
import "@hotwired/turbo-rails"
import 'stylesheets/application.scss'
import { Tooltip, Toast, Popover, Modal } from 'bootstrap'
import NProgress from 'nprogress'
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import ytdefer from 'vendor/ytdefer.js'
import { get } from '@rails/request.js'

import common from './common'

require.context('../images', true)

window.bootstrapModal = Modal

Rails.start()

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

document.addEventListener("turbo:click", e => {
  NProgress.start()
})

document.addEventListener("turbo:render", e => {
  NProgress.done()
  NProgress.remove()
})

document.addEventListener("turbo:load", e => {
  ytdefer()
})

document.addEventListener("ajax:before", e => {
  NProgress.start()
})

document.addEventListener("ajax:complete", e => {
  NProgress.done()
  NProgress.remove()
})

// Generally we should use tubo to navigate using ajax.
// However, there are some cases where we do not want
// to update the window location (no change to history).
// In these cases, we can use data-turbo-remote='true'.
document.addEventListener('click', e => {
  const el = e.target
  if (el.tagName == 'A' && el.dataset.turboRemote == 'true') {
    e.preventDefault()
    e.stopPropagation()
    get(el.href)
  }
})