import { Controller } from "stimulus"

let appId = 'xegtkfs8i';

export default class extends Controller {
  static values = { subscriptionid: String, customerid: String, authhash: String, mode: String }

  connect() {
    if (!window.churnkey || !window.churnkey.created) {
      window.churnkey = { created: true };
      const a = document.createElement('script');
      a.src = `https://assets.churnkey.co/js/app.js?appId=${appId}`;
      a.async = true;
      const b = document.getElementsByTagName('script')[0];
      b.parentNode.insertBefore(a, b);
    }
  }

  cancelSubscription(e) {
    e.preventDefault();

    window.churnkey.init('show', {
      subscriptionId: this.subscriptionidValue,
      customerId: this.customeridValue,
      authHash: this.authhashValue,
      mode: this.modeValue,
      appId: `${appId}`,
      provider: 'stripe',
      record: true, // set to false to skip session playback recording
      handleCancel: function (data) {
        return new Promise(async (resolve, reject) => {
          try {
            fetch('/subscription', { headers: { 'Accept': 'application/json'}, method: "DELETE" } )
            .then(response => response.json())
            .then((data) => {
              window.location.href = '/subscription';

            } );
            resolve({ message: 'Your account has been canceled. You will not be billed again' });
          } catch (e) {
            console.log(e);
            reject({ message: 'Failed to cancel account. Please reach out to us through support.' });
          }
        });
      },
      onGoToAccount: function (data) {
        window.location.href = '/subscription';
      },
      onClose: function (data) {
        window.location.href = '/subscription';
      }
    });
  }
}