import { Controller } from "stimulus"

export default class extends Controller {

    toggle(e) {
      // toggle collapse class

      e.preventDefault();

      let accordionLink; 
      if (e.target.dataset.target) {
        accordionLink = e.target;
      } else {
        accordionLink = e.target.parentElement;
      }
      
      const panel = document.querySelector(accordionLink.dataset.target);
      panel.classList.toggle('show');
      const arrow = accordionLink.children[1];

      if ( arrow.classList.contains('fa-angle-down') ) {
        arrow.classList.toggle('fa-angle-up');
      } else {
        arrow.classList.toggle('fa-angle-down');
      }
    }
}