import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.myModal = new bootstrapModal(this.element);
    this.myModal.show();
  }

  disconnect() {
    this.myModal.hide()
    this.myModal.dispose()
  }
}