import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'addressText' ];

  copy(e) {
    e.preventDefault();

    const copyText = this.addressTextTarget.innerText;
    navigator.clipboard.writeText(copyText)
    alert("Address copied!");
  }
}