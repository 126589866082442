import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "category", "links" ]


    display(event) {
        event.preventDefault();
        const hiddenLinks = event.target.parentElement.querySelector('.nested-links');
        hiddenLinks.classList.toggle('d-none');
    }
}

