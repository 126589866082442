import { Controller } from "stimulus"

export default class extends Controller {

  nextPage(event) {
    // listen for the ajax success && insert the new page in the active tab
    
    event.preventDefault();
    const [data, status, xhr] = event.detail;
    const updatePanel = document.querySelector('.tab-pane.active');
    const newPage = new XMLSerializer().serializeToString(data);
    updatePanel.innerHTML = newPage;
  }
}