import { Controller } from "stimulus"
let singleMap;

export default class extends Controller {
  static targets = [ 'singleMap' ]
  
  connect() {
    const marker = document.querySelector('[data-items]');
    let item = JSON.parse(marker.dataset.items);
    this.buildMap(item);
  }

  buildMap(item) {
    // generate the mapbox map
    
    mapboxgl.accessToken = mapboxToken;

    singleMap = new mapboxgl.Map({
      container: 'single-map',
      center: [item.longitude, item.latitude],
      zoom: 9,
      style: mapboxStreetStyle
    });
    
    singleMap.dragRotate.disable();
    singleMap.touchZoomRotate.disableRotation();
    singleMap.addControl(new mapboxgl.ScaleControl({ unit: 'imperial' }));
    document.querySelectorAll('.mapboxgl-ctrl-compass, .mapboxgl-ctrl-logo').forEach ((item) => {item.style.visibility = 'hidden'});
    this.addMarker(item);
    return singleMap;
  }

  addMarker(item) {
    // display marker on the map

    if(item.mapicon === undefined) {
      item.mapicon = 'rv';
    }
    
    let el = document.createElement('div');
      el.className = 'marker state-' + item.state_id;
      let mapicon = document.querySelector(`#${item.mapicon}-svg`);
      el.style.backgroundImage = window.getComputedStyle(mapicon).backgroundImage;
      el.style.width = '28px';
      el.style.height = '28px';

    new mapboxgl.Marker(el)
      .setLngLat([item.longitude, item.latitude])
      .addTo(singleMap);
  }
}