import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'activityFeed' ]
  
  connect() {
    this.lazyLoadImages();
    const loadBtns = document.querySelectorAll('.load-next');
    this.loadMoreListener(loadBtns);
  }

  lazyLoadImages() {
    // lazy load the images on the activity feed

    const lazyloadImages = document.querySelectorAll(".activity-lazyload");

    const imageObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const image = entry.target;
          image.src = image.dataset.src;
          image.classList.remove("activity-lazyload");
          imageObserver.unobserve(image);
        }
      });
    });

    lazyloadImages.forEach((image) => {
      imageObserver.observe(image);
    });
  }

  loadMore(url) {
    // ajax request to load the next set of activities on the activity feed

    fetch(url, {
      headers: { 'Accept': 'application/json'}
    })
    .then(response => response.json())
    .then((data) => {
      this.activityFeedTarget.insertAdjacentHTML('beforeend', data.entries);
      const loadBtns = document.querySelectorAll('.load-next');
      this.loadMoreListener(loadBtns);
    })
  }

  loadMoreListener(loadBtns) {
    // add a listener for the load more button at the bottom & hide the rest
    // call the lazy load for the new set of photos
    
    loadBtns.forEach((btn) => {
      btn.addEventListener('click', (event) => {
        event.preventDefault();
        event.target.classList.add('hidden');
        this.loadMore(btn.href);
      })
    })
    this.lazyLoadImages();
  }
}
