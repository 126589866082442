import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'stateId', 'cityId', 'cityTitleDroplist' ]

  connect() {
    this.autocompleteCityTitles();
  }

  autocompleteCityTitles(e) {
    // generates an autocomplete dropdown with the available cities

    const selected = this.stateIdTarget.selectedIndex;
    const stateId = this.stateIdTarget.children[selected].value;

    if (stateId) {
      fetch(`/cities?state_id=${stateId}`, {
        headers: { 'Accept': 'text/plain'}
      })
      .then(response => response.text())
      .then((data) => {
        const cityList = document.querySelector('#city_list');
        cityList.innerHTML = '';
        const optionList = data.split('\n').reverse();
        let htmlObject = document.createElement('option');
        cityList.appendChild(htmlObject);
        optionList.forEach((option) => {
          htmlObject.insertAdjacentHTML('afterend', option);
        })
      })
    }
  }
}