import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'favoriteBtn' ]
  
  favoriteCampground(e) {
    e.preventDefault();

    if ( this.favoriteBtnTarget.innerText == ' Add Property to Favorites' ) {
      // if unfavorited, add to favorites

      fetch(this.favoriteBtnTarget.href, {
        method: "POST",
        headers: { "Accept": "application/json" }
      })
      .then(response => response.json())
      .then((data) => {
        const favoriteBtn = document.querySelector('.favorite-btn');
        favoriteBtn.innerHTML = '<i class="fa-heart fa-solid"></i> <span style="padding-left: 0.4rem;"> Added to Favorites</span>';
        alert("Property added to favorites.");
      })
    } else {
      alert("Already added to favorites.");
    }

  }
}