import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'upcomingTab' ]
  
  connect() {
    this.hideTabs();
    this.reservationTabs();
  }

  reservationTabs() {
    // display reservation tabs on my reservations

    const cgTabs = document.querySelectorAll('#cg-tabs li');
    cgTabs.forEach ((tab) => {
      tab.width = tab.width + 10;
    })
    cgTabs[0].classList.add('active');

    const activeContent = document.querySelectorAll('#cg-tab-content .tab-pane')[0];
    activeContent.classList.add('active', 'in');
    activeContent.classList.remove('fade');

    const liveTabs = document.querySelectorAll('#cg-tabs a');
    const panel = document.querySelector(liveTabs[0].getAttribute('href'));
    this.displayPanel(panel);
  }

  displayTab(e) {
    // display the selected tab

    e.preventDefault();
    this.hideTabs();
    const panel = document.querySelector(e.currentTarget.children[0].getAttribute('href'));
    if (panel) {
      this.activeNavTab(e.currentTarget);
      this.displayPanel(panel);
    }
  }

  hideTabs() {
    // hide all reservation tabs

    const allPanels = document.querySelectorAll('.tab-pane');
    allPanels.forEach((tab) => {
      tab.classList.add('fade');
      tab.style.opacity = 0;
      tab.style.height = 0;
    })
  }

  activeNavTab(target) {
    // update active nav
    
    const activeTab = document.querySelector('li.active');
    activeTab.classList.remove('active');
    target.classList.add('active');
  }

  displayPanel(panel) {
    // display active panel

    panel.classList.add('active');
    panel.classList.remove('fade');
    panel.style.opacity = 'inherit';
    panel.style.height = 'auto';
  }
}