import { Controller } from "stimulus"

let elements = '';

export default class extends Controller {
  static targets = ["form", "generalError", "phoneInput", "phoneError", "emailInput", "emailError", "firstNameInput", "firstNameError", "lastNameInput", "lastNameError", "equipmentLengthError", "equipmentWidthError", "equipLength", "equipWidth", "maxLength", "maxWidth", "stripeForm", "paymentIntent", "bookingId", "submitBtn"]

  connect() {
    // set or check session
    this.setSession();

    // load stripe
    this.loadStripe();

    // scroll to error if form is re rendered
    
    const helpText = document.querySelector('.help-inline');
    if (helpText) {
      const offset = helpText.getBoundingClientRect().top + window.pageYOffset + -88;
      window.scrollTo({top: offset, behavior: 'smooth'});
    }

  }

  setSession() {
    // set session time if it does not exist

      if (sessionStorage.getItem('sessionExpiration')) {
        this.checkSession();
      } else {
        this.createSession();
      }
  }

  createSession() {
    // set session info

    const currentTime = new Date();
    const ttl = currentTime.setMinutes(currentTime.getMinutes() + 20);
    const sessionExpiration = {
      expiresAt: ttl,
    }
    sessionStorage.setItem('sessionExpiration', JSON.stringify(sessionExpiration));
  }

  checkSession() {
    // check if session is expired & if so, clear the session && redirect

    const ttl = new Date(JSON.parse(sessionStorage.getItem('sessionExpiration')).expiresAt);
    const currentDate = new Date();

    if (ttl < currentDate) {
      sessionStorage.removeItem('sessionExpiration');
      window.location.href = this.formTarget.action.replace('/booking', '?booking_error=booking_time_out');
    }
  }

  // VALID FORMATS

  validatePhoneFormat(phone){
    // validate phone number format

    var phoneRe = /^(?:\+?(\d{1,3}))?[ ]?[-. (]?(\d{3})[-. )]?[- ]?(\d{3})[-. ]?(\d{4})(?: *x(\d+))?$/;
    return phoneRe.test(phone);
  }

  validateEmailFormat(email){
    // validate email format

    var emailRe = /^(\S+)@(.+)\.(\S+)$/;
    return emailRe.test(email);
  }


  // ADD / REMOVE ERRORS

  addError(input, errorInput, message) {
    // add error styling

    input.classList.add('input-border-red');
    errorInput.innerText = message;
  }

  removeError(input, errorInput) {
    // remove error styling

    input.classList.remove('input-border-red');
    errorInput.innerText = '';
  }

  clearErrors(e) {
    // remove errors

    e.target.parentElement.lastElementChild.innerText = '';
    e.target.parentElement.parentElement.parentElement.lastElementChild.innerText = '';
    e.target.style.borderColor = 'gray';
  }


  // CHECK FOR ERRORS

  checkPhoneErrors() {
    // display errors on blur for phone number

    const noError = (this.validatePhoneFormat(this.phoneInputTarget.value));
    if (noError) {
      this.removeError(this.phoneInputTarget, this.phoneErrorTarget);
      return true;
    } else {
      this.addError(this.phoneInputTarget, this.phoneErrorTarget, 'Enter a valid phone number');
      return false;
    }
  }

  checkEmailErrors() {
    // display errors on blur for phone number

    const noError = (this.validateEmailFormat(this.emailInputTarget.value));
    if (noError) {
      this.removeError(this.emailInputTarget, this.emailErrorTarget);
      return true;
    } else {
      this.addError(this.emailInputTarget, this.emailErrorTarget, 'Enter a valid email');
      return false;
    }
  }

  checkFirstNameErrors() {
    // display errors on blur for first name

    const noError = (this.firstNameInputTarget.value.length >= 2);
    if (noError) {
      this.removeError(this.firstNameInputTarget, this.firstNameErrorTarget);
      return true;
    } else {
      this.addError(this.firstNameInputTarget, this.firstNameErrorTarget, 'First name is required');
      return false;
    }
  }

  checkLastNameErrors() {
    // display errors on blur for last name

    const noError = (this.lastNameInputTarget.value.length >= 2);
    if (noError) {
      this.removeError(this.lastNameInputTarget, this.lastNameErrorTarget);
      return true;
    } else {
      this.addError(this.lastNameInputTarget, this.lastNameErrorTarget, 'Last name is required');
      return false;
    }
  }

  checkEquipmentLengthErrors() {
      // display errors on blur for equipment length

      const maxLength = parseInt(this.maxLengthTarget.innerText.match(/\d+/)[0]);
      const equipLength = parseInt(this.equipLengthTarget.value);
      const noError = (this.equipLengthTarget.value.length >= 1 && equipLength <= maxLength && equipLength > 0);

      if (noError) {
        this.removeError(this.equipLengthTarget, this.equipmentLengthErrorTarget);
        return true;
      } else if (this.equipLengthTarget.value.length < 1 || equipLength === 0) {
        this.addError(this.equipLengthTarget, this.equipmentLengthErrorTarget, 'Equipment length is required');
        return false;
      } else {
        this.addError(this.equipLengthTarget, this.equipmentLengthErrorTarget, `Must be less than ${maxLength} feet`);
        return false;
      }
  }

  checkEquipmentWidthErrors() {
    // display errors on blur for equipment width

    const maxWidth = parseInt(this.maxWidthTarget.innerText.match(/\d+/)[0]);
    const equipWidth = parseInt(this.equipWidthTarget.value);
    const noError = (this.equipWidthTarget.value.length >= 1 && equipWidth <= maxWidth && equipWidth > 0);

    if (noError) {
      this.removeError(this.equipWidthTarget, this.equipmentWidthErrorTarget);
      return true;
    } else if (this.equipWidthTarget.value.length < 1 || equipWidth === 0) {
      this.addError(this.equipWidthTarget, this.equipmentWidthErrorTarget, 'Equipment width is required');
      return false;
    } else {
      this.addError(this.equipWidthTarget, this.equipmentWidthErrorTarget, `Must be less than ${maxWidth} feet`);
      return false;
    }      
  }

  loadStripe() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://js.stripe.com/v3/';
    document.body.appendChild(script);
    script.onload = () => {
      let stripeMeta = document.querySelector('meta[name="stripe-key"]');
      if (stripeMeta === null) { return };
      
      let url = document.querySelector('.new_booking_form').action + '/stripe';
      fetch(url, { headers: { "Accept": "application/json" } })
        .then(response => response.json())
        .then((data) => {
          
          let stripeKey = stripeMeta.getAttribute("content")
          this.stripe   = Stripe(stripeKey)

          const appearance = {
            theme: 'stripe',
            // labels: "floating",
            variables: {
                colorPrimaryText: '#3B3F5B',
                colorText: '#3B3F5B',
                fontFamily: 'Lato, Arial, sans-serif',
                borderRadius: '4px',
                fontSizeBase: '1.1em',
                fontSizeXs: '0.5em',
                fontWeightNormal: '400',
                spacingGridRow: '2rem',
                spacingGridColumn: '1rem',
                colorDanger: '#d73551',
            },
            rules: {
              '.Input': {
                border: '1px solid #757575',
                boxShadow: 0,
              },
              '.Input--invalid': {
                border: '1px solid #d73551',
                boxShadow: 0
              },
              '.Input:focus': {
                boxShadow: 0,
              },
              '.Label': {
                fontFamily: 'Lato, Arial, sans-serif',
                fontSize: '0.7em',
                fontWeight: '600',
                letterSpacing: '0.095em',
                textTransform: 'uppercase'
              },
              '.Error': {
                fontSize: '0.8em',
              }
            }
        };
          
          elements = this.stripe.elements({ appearance, clientSecret: data['client_secret'] });
          const paymentElement = elements.create("payment");
          paymentElement.mount("#payment-element");
      })

    }
  }

  // ANALYTICS

  analyticsPurchaseProperties() {
    // analytics for placeProperties && bookingProperties && campsiteProperties && userProperties && paymentProperties

    const campgroundPlaceProperties = JSON.parse(document.querySelector('#booking_form_place_properties').value);
    const bookingDetailsProperties = JSON.parse(document.querySelector('#booking_form_booking_info').value);

    const bookingProperties = {
      booking_type: 'Direct',
      booking_checkin_date: new Date(bookingDetailsProperties.start_date).toISOString(),
      booking_checkout_date: new Date(bookingDetailsProperties.end_date).toISOString(),
      booking_guest_count: parseInt(bookingDetailsProperties.number_of_guests),
      context: 'Checkout Page',
      vehicle_length: this.equipLengthTarget.value,
      vehicle_model: document.querySelector('#booking_form_equipment_type').textContent,
      vehicle_width: this.equipWidthTarget.value,
      booking_confirmation_number: document.querySelector('#booking_form_booking_confirmation').value
    };

    const campsiteProperties = {
      campsite_name: bookingDetailsProperties.campsite_name,
      campsite_id: String(document.querySelector('#booking_form_campsite_id').value),
      campsite_price: parseFloat(bookingDetailsProperties.average_rate.slice(1, -1)),
      campsite_cancel_policy: bookingDetailsProperties.cancel_text
    }
    
    const paymentProperties = {
      payment_provider: 'Stripe',
      payment_promo_code: '',
      payment_total_amount: parseFloat(bookingDetailsProperties.total.slice(1, -1)),
      payment_discount_amount: parseFloat(bookingDetailsProperties.service_fee.slice(1, -1)),
    }

    const analyticsProperties = {
        ...campgroundPlaceProperties,
        ...bookingProperties,
        ...campsiteProperties,
        ...paymentProperties
    };

    return analyticsProperties;
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setLoading(true);
    event.stopPropagation();

    // check if records are valid

    if (this.checkPhoneErrors() && this.checkEmailErrors() && this.checkFirstNameErrors() && this.checkLastNameErrors() && this.checkEquipmentLengthErrors() && this.checkEquipmentWidthErrors() ) {

      // call updateBooking

      let url = document.querySelector('.new_booking_form').action;
      fetch(url, {
        method: "PATCH",
        headers: {
          "Accept": "application/json"
        },
        body: new FormData(this.formTarget)
      })
      .then(response => response.json())
      .then((data) => {
        
        if (data["response"] === 204 || data["response"]["error"] !== "unsuccessful") {
          // once valid, create stripe
          this.submitStripe();
          const analyticsProps = this.analyticsPurchaseProperties();
          window.campAnalytics.trackBookingPurchaseCompleted(analyticsProps);
        } else if (data["response"]["error"] === "unsuccessful") {
          setLoading(false);
          this.showMessage("Sorry, we're unable to complete your request at this time.  Please try again later.");
        }

      })
      .catch(err => {
        this.setLoading(false);
        this.showMessage("Sorry, we're unable to complete your request at this time.  Please try again later.");
      });

    } else {
      this.checkPhoneErrors()
      this.checkEmailErrors()
      this.checkFirstNameErrors()
      this.checkLastNameErrors()
      this.checkEquipmentLengthErrors()
      this.checkEquipmentWidthErrors()
      this.setLoading(false);
      this.scrollToError(".input-border-red");
    }
  }

  submitStripe() {
    this.stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: this.formTarget.action + `/confirmation/${document.querySelector('meta[name="booking-id"]').content}`
      },
    }).then((result) => {
      
      if (result.error) {
        // throw stripe error if there is an immediate error

        if (result.error.type === "card_error" || result.error.type === "validation_error") {
          this.setLoading(false);
          this.showMessage("Sorry, there's an issue with your payment information. Please make sure your card information is correct or try a different card.");
        } else {
          this.setLoading(false);
          this.showMessage("Sorry, we're unable to complete your transaction at this time.  Please try again later.");
        }
        this.scrollToError(".Error");

      } 
    })
  }

  setLoading(isLoading) {
    // update text for button

    if (isLoading) {
      this.submitBtnTarget.innerHTML = `<i class="fas fa-circle-notch fa-spin"></i> <span style="padding-left: 0.4rem;">Processing...</span>`;
    } else {
      this.submitBtnTarget.innerHTML = "Book Now";
    }
  }

  showMessage(messageText) {
    // add error message for stripe

    const messageContainer = this.generalErrorTarget;
    const checkoutHeader = document.querySelector('.booking-color');
    messageContainer.classList.add("input-error");
    messageContainer.classList.add('input-border-red')
    messageContainer.style.display = 'block';
    messageContainer.textContent = messageText;
    checkoutHeader.scrollIntoView();
  }

  scrollToError(errorClass) {
    // scroll to error upon submit

    const element = document.querySelector(errorClass);
    if (element) {
      element.scrollIntoView();
    }
  }
}

