import { Controller } from "stimulus"
export default class extends Controller {
  static targets = [ "card", "name", "error", "form", "planOption", "planInput", "planDesc", "submitButton", "terms", "coupon", "couponApplied", "applyBtn", "totalAmount", "cardAutomaticText", "totalAmountDetail" ]

  connect() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://js.stripe.com/v3/';
    document.body.appendChild(script);
    script.onload = () => {
      this.originalSubmitButtonContent = this.submitButtonTarget.textContent
      this.stripeEnabled = this.cardTargets.length > 0
      if (this.stripeEnabled){
        let stripeMeta = document.querySelector('meta[name="stripe-key"]')
        if (stripeMeta === null) { return }

        let stripeKey = stripeMeta.getAttribute("content")
        this.stripe   = Stripe(stripeKey)
        let elements  = this.stripe.elements()

        // Setup Intents are used for adding new cards to be charged in the future
        this.setup_intent = this.data.get("setup-intent")

        // Payment intents are for processing payments that require action
        this.payment_intent = this.data.get("payment-intent")

        // Setup regular payments
        this.card = elements.create("card", { style:
          {
            base: {
              fontSize: '16px',
              fontFamily: 'Lato, Arial, sans-serif',
              lineHeight: '24px'
            }
          }
        })
        this.card.mount(this.cardTarget)
        this.card.addEventListener("change", this.changed.bind(this))
      }
    }
  }

  planChange(event) {
    event.preventDefault()
    this.planOptionTargets.forEach(t => t.classList.remove('selected'))
    event.currentTarget.classList.add('selected')
    this.planInputTarget.value = event.currentTarget.dataset.planId
    this.planDescTarget.textContent = event.currentTarget.dataset.desc
  }

  handleSubmit(event) {
    event.preventDefault()
    event.stopPropagation()
    this.submit(event)
  }

  changed(event) {
    if (event.error) {
      this.errorTarget.textContent = event.error.message
    } else {
      this.errorTarget.textContent = ""
    }
  }

  keydown(event) {
    if (event.keyCode == 13) {
      // Catch Enter key's form submission and process as submit
      event.preventDefault()
      this.submit(event)
    }
  }

  applyCoupon(event) {
    event.preventDefault()

    if ( !!this.couponTarget.value ) {

      const url = `${this.applyBtnTarget.href}/${this.couponTarget.value}`
      fetch(url, { headers: { 'Accept': 'application/json' } })
        .then(response => response.json())
        .then((data) => {
          let couponResponse = ''
          const originalAmount = parseFloat(document.querySelector('.amount').dataset.value)
          if (data) {
            couponResponse = '12 Month Coupon Code Applied'
            
            let newAmount = 0
            // uses the first appliedCoupon && checks if it's a coupon with an amount_off or percent_off
            if ( data['amount_off'] ) {
              const amountOff = data['amount_off']
              newAmount = ((originalAmount * 100) - amountOff) / 100
            } else {
              const percentOff = data['percent_off']
              newAmount = ((originalAmount) - ((percentOff / 100) * originalAmount) ).toFixed(2)
            }
              
            this.totalAmountTarget.innerHTML = `<span>
                                                  <span style='color:red;text-decoration:line-through'>
                                                    <span style='color:white'>$${originalAmount}</span>
                                                  </span> 
                                                   $${newAmount}
                                                </span>`
            
            const detailText = `$${newAmount} for the first year, then $${originalAmount} per year`
            this.totalAmountDetailTarget.textContent = detailText
  
            const textAlertBelow = 'Your credit card will be automatically charged yearly.  You can cancel anytime.'
            this.cardAutomaticTextTarget.textContent = textAlertBelow
  
            this.formTarget.insertAdjacentHTML('beforeend', 
              `<input type="hidden" id="promoId" name="user[coupon_id]" value="${data['id']}">`)
  
          } else {
            couponResponse = 'Invalid Coupon Code'
  
            this.totalAmountTarget.innerHTML = `<span>
                                                  $${originalAmount}
                                                </span>`
  
            this.cardAutomaticTextTarget.textContent = `Your credit card will be automatically charged $${originalAmount} yearly.  You can cancel anytime.`
  
            this.totalAmountDetailTarget.textContent = 'Billed once per year'
          }
  
          this.couponAppliedTarget.textContent = couponResponse
      })

    } else {
      this.couponAppliedTarget.textContent = 'Invalid Coupon Code'
    }
  }

  disableSubmitButton() {
    this.submitButtonTarget.innerHTML = this.submitButtonTarget.dataset.disableWith
    this.submitButtonTarget.disabled = true
  }

  enableSubmitButton() {
    this.submitButtonTarget.innerHTML = this.originalSubmitButtonContent
    this.submitButtonTarget.disabled = false
  }

  submit(event) {
    event.preventDefault()
    this.errorTarget.textContent = ""

    if (this.stripeEnabled && this.nameTarget.value == "") {
      this.errorTarget.textContent = "Name on card is required."
      return
    }

    if (this.termsTargets.length > 0 && !this.termsTarget.checked) {
      this.errorTarget.textContent += "You must agree to the terms & conditions."
      return
    }

    if (this.stripeEnabled) {
      this.disableSubmitButton()

      // One time payments
      if (this.payment_intent) {
        this.handleCardPayment()

        // Updating card with setup intent
      } else if (this.setup_intent) {
        this.setupNewCard()

      // Subscriptions simply tokenize the payment method and redirect to payment page if SCA required
      } else {
        this.stripe.createPaymentMethod({
          type: 'card',
          card: this.card,
          billing_details: {
            name: this.nameTarget.value
          },
        }).then((result) => {
          if (result.error) {
            this.errorTarget.textContent = result.error.message
            this.enableSubmitButton()
            return
          }
          this.handlePaymentMethod(result.paymentMethod)
        })
      }
    } else {
      this.formTarget.submit()
    }
  }

  setupNewCard() {
    let data = {
      payment_method: {
        card: this.card,
        billing_details: {
          name: this.nameTarget.value
        }
      }
    }

    this.stripe.confirmCardSetup(this.setup_intent, data).then((result) => {
      if (result.error) {
        this.errorTarget.textContent = result.error.message
        this.enableSubmitButton()
      } else {
        this.handlePaymentMethod(result.setupIntent.payment_method)
      }
    })
  }

  handlePaymentMethod(payment_method) {
    this.addHiddenField("user[processor]", "stripe")
    this.addHiddenField("user[card_token]", payment_method.id)
    this.addHiddenField("user[zip_code]", payment_method.billing_details.address.postal_code)

    this.formTarget.submit()
  }

  addHiddenField(name, value) {
    let hiddenInput = document.createElement("input")
    hiddenInput.setAttribute("type", "hidden")
    hiddenInput.setAttribute("name", name)
    hiddenInput.setAttribute("value", value)
    this.formTarget.appendChild(hiddenInput)
  }

  handleCardPayment() {
    // Handle an existing payment that needs confirmation
    this.stripe.confirmCardPayment(this.payment_intent).then((result) => {
      if (result.error) {
        this.errorTarget.textContent = result.error.message
        this.enableSubmitButton()

      } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        Turbo.clearCache()
        Turbo.visit("/")
      }
    })
  }
}
