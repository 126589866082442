import { Controller } from "stimulus"
let favoritesMap;

export default class extends Controller {
  static targets = [ 'tabFavorites', 'favoritesMap', 'favoritesMapWrapper', 'activityTabs', 'backToTopBtn' ]
  
  connect() {
    this.hideTabs();
    favoritesMap = this.buildMap();
    this.activityTabs();

    document.addEventListener('scroll', (e) => { 
      this.displayBackToTop();
    })
  }

  activityTabs() {
    // display activity tabs on activity feed

    const cgTabs = document.querySelectorAll('#cg-tabs li');
    cgTabs.forEach ((tab) => {
      tab.width = tab.width + 10;
    })
    cgTabs[0].classList.add('active');

    const activeContent = document.querySelectorAll('#cg-tab-content .tab-pane')[0];
    activeContent.classList.add('active', 'in');
    activeContent.classList.remove('fade');

    const liveTabs = document.querySelectorAll('#cg-tabs a');
    if (liveTabs.length == 1 && liveTabs[0].getAttribute('href')) {
      this.favoritesMapWrapperTarget.height = '430px';
    }

    const panel = document.querySelector(liveTabs[0].getAttribute('href'));
    this.displayPanel(panel);
    if (liveTabs[0].getAttribute('href') == '#favorites') {
      this.displayMap();
    }
  }

  displayTab(e) {
    // display selected tab

    e.preventDefault();
    if (e.currentTarget.children[0].getAttribute('href') == '#favorites' && this.favoritesMapWrapperTarget.height == 0) {
      this.favoritesMapWrapperTarget.height = '430px';
    } else {
      this.hideTabs();
      const panel = document.querySelector(e.currentTarget.children[0].getAttribute('href'));
      if (panel) {
        this.activeNavTab(e.currentTarget);
        this.displayPanel(panel);
      }
      if (e.currentTarget.children[0].getAttribute('href') == '#favorites') {
        this.displayMap();
      }
    }
  }

  displayPanel(panel) {
    // display active panel

    panel.classList.add('active');
    panel.classList.remove('fade');
    panel.style.opacity = 'inherit';
    panel.style.height = 'auto';
  }

  activeNavTab(target) {
    // update active nav
    
    const activeTab = document.querySelector('li.active');
    activeTab.classList.remove('active');
    target.classList.add('active');
  }

  hideTabs() {
    // hide all activity tabs + map

    this.favoritesMapTarget.style.height = 0;
    const allPanels = document.querySelectorAll('.tab-pane');
    allPanels.forEach((tab) => {
      tab.classList.add('fade');
      tab.classList.remove('active');
      tab.style.opacity = 0;
      tab.style.height = 0;
    })
  }

  expandFavoritesList(e) {
    // expand list of favorites on favorites tab
    
    e.preventDefault();
    const link = e.currentTarget;

    if (link.classList.contains('expanded')) {
      link.nextElementSibling.remove();
      link.classList.remove('expanded');
    } else {
      link.classList.add('expanded', 'recent-favorite');
      link.insertAdjacentHTML('afterend', `<div class='favorites-wrapper'></div>`);
      fetch(link.href, {
        headers: { 'Accept': 'text/plain'}
      })
      .then(response => response.text())
      .then((data) => {
        const link = document.querySelector('.recent-favorite');
        const linkSibling = link.nextElementSibling;
        if (linkSibling.classList.contains('favorites-wrapper')) {
          linkSibling.innerHTML = data;
        }
        link.classList.remove('recent-favorite');
        this.favoritesMarkers(link);
      })
    }
  }

  buildMap() {
    // generate the mapbox map
    
    if (this.favoritesMapTarget && !this.favoritesMapTarget.classList.contains('js-favorites-zero')) {
      mapboxgl.accessToken = mapboxToken;
      
      favoritesMap = new mapboxgl.Map({
        container: 'favorites-map',
        center: [-98.5795,39.8283],
        zoom: 3,
        style: mapboxStreetStyle
      });
      
      favoritesMap.dragRotate.disable();
      favoritesMap.touchZoomRotate.disableRotation();
      favoritesMap.addControl(new mapboxgl.ScaleControl({ unit: 'imperial' }));
      document.querySelectorAll('.mapboxgl-ctrl-compass, .mapboxgl-ctrl-logo').forEach ((item) => {item.style.visibility = 'hidden'});
      return favoritesMap;
    }
  }

  displayMap() {
    // display the map

    this.favoritesMapTarget.style.height = '300px';
  }

  favoritesMarkers(link) {
    // display markers on the map

    const markers = link.nextElementSibling.querySelector('[data-items]');
    let items = JSON.parse(markers.dataset.items)
    items.forEach((item) => {
      let contentString = `<a href="/${item.slug}" target="_blank"><div class="map-info-bubble">`;
      const bookable = item.booking_url_type !== "Not Bookable";
      if (item.sq_img !== null) {
        contentString += '<div class="info-bubble-img">' +
          '<img src="' + item.sq_img + '" alt="' + item.title +'">' + '</div>'
      }
      contentString += '<div class="info-bubble-content">'
      if (bookable) {
        contentString += '<div class="booking-pill">Bookable</div>'
      }
      contentString += '<p class="info-bubble-title">' + item.title + '</p>' +
        '<p>' + item.city_state.replace(/, $/, '') + '</p>' +
        '<p>' + item.category_title + '</p>' +
        '<p>' +
          '<span class="stars">' +
            '<span class="stars' + item.stars + '"></span>' +
          '</span>' +
        '</p>' +
        '</div>' +
        '</div>' +
        '</a>'
        ;
      if (bookable) {
        contentString += 
        `<div class="btn-availability-map-wrapper"><a class="btn btn-large btn-warning btn-availability-cta" href="/${item.slug}">Check Availability</a></div>`
      }
  
      let popup = new mapboxgl.Popup({ offset: 25 })
        .setHTML(contentString);
  
      if(item.mapicon === undefined) {
        item.mapicon = 'rv';
      }
      let el = document.createElement('div');
        el.className = 'marker state-' + item.state_id;
        let mapicon = document.querySelector(`#${item.mapicon}-svg`);
        el.style.backgroundImage = window.getComputedStyle(mapicon).backgroundImage;
        el.style.width = '28px';
        el.style.height = '28px';
  
      new mapboxgl.Marker(el)
        .setLngLat([item.longitude, item.latitude])
        .setPopup(popup)
        .addTo(favoritesMap);

    });
  }

  displayBackToTop() {
    // display back to top button

    const backToTopThreshold = 2000;
    let scrollYPosition = window.pageYOffset || document.documentElement.scrollTop;
    
    if (scrollYPosition > backToTopThreshold) {
      this.backToTopBtnTarget.style.opacity = 1;
    } else {
      this.backToTopBtnTarget.style.opacity = 0;
    }
  }

  scrollToTop(e) {
    // scroll to navigation

    this.activityTabsTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
