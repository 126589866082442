import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'rvLength', 'userRvProfile', 'rvMake', 'rvMakeOther', 'rvModel', 'towVehicleMake', 'towVehicleMakeOther', 'towVehicleModel', 'equipmentType', 'equipmentOther', 'uploadProfile' ]

  connect() {
    this.rvFillData()
  }

  autocompleteRVLength(e) {
    // generates a value of 50+ feet or xx feet for rv_length input
    
    const inputValue = parseInt(e.target.value.replace(/\D+/, ''));
    this.rvLengthTarget.value = '';
    let finalValue = ''
    if (inputValue < 50) {
      finalValue = `${inputValue} feet`
    } else if (inputValue == 0 || isNaN(inputValue) ) { 
      finalValue = ''
    } else {
      finalValue = '50+ feet'
    }

    this.rvLengthTarget.value = finalValue;
  }

  equipmentTypeToggle(e) {
    // unhide related inputs when user makes an equipment selection
    
    document.querySelectorAll('#user_rv_profile > *:not(#equipment_type-wrapper)').forEach((element) => { 
      element.classList.add('hidden');
    });
    
    e.target.querySelector(`option[value='${e.target.value}']`).dataset.steps.split(',').forEach((dataStep) => {
      document.getElementsByName(`${dataStep}`)[0].parentElement.classList.remove('hidden');
      if (document.getElementsByName(`${dataStep}`)[0].tagName === 'SELECT') {
        document.getElementsByName(`${dataStep}`)[0].parentElement.parentElement.classList.remove('hidden');
      }
    })

    this.clearAllValues();
  }

  rvMakeToggle(e) {
    // unhide RV Make Other input

    if (e.target.value == 'Other') {
      this.unhideInput(this.rvMakeOtherTarget.parentElement);
    } else {
      this.hideInput(this.rvMakeOtherTarget.parentElement);
    }
  }

  towVehicleMakeToggle(e) {
    // unhide tow vehicle Make Other input

    if (e.target.value == 'Other') {
      this.unhideInput(this.towVehicleMakeOtherTarget.parentElement);
    } else {
      this.hideInput(this.towVehicleMakeOtherTarget.parentElement);
    }
  }

  unhideInput(element) {
    element.classList.remove('hidden');
  }

  hideInput(element) {
    element.classList.add('hidden');
  }

  unhideRV(userRv) {
    // display if existing rv vehicle

    if (userRv.rv_make !== null) {
      this.unhideInput(this.rvMakeTarget.parentElement.parentElement);
    } 

    if (userRv.rv_model !== null) {
      this.unhideInput(this.rvModelTarget.parentElement);
    }

    if (userRv.rv_length !== null) {
      this.unhideInput(this.rvLengthTarget.parentElement);
    }
  }

  unhideTow(userRv) {
    // display if existing tow vehicle

    if (userRv.tow_vehicle_make !== null && userRv.tow_vehicle_model !== null) {
      this.unhideInput(this.towVehicleMakeTarget.parentElement.parentElement);
      this.unhideInput(this.towVehicleModelTarget.parentElement);
    } 
  }

  unhideOther(userRv) {
    // display if existing other equipment

    if (userRv.equipment_other !== null) {
      this.unhideInput(this.equipmentOtherTarget.parentElement);
    }
  }

  clearAllValues() {
    // if changing equipment, clear values elsewhere

    this.rvLengthTarget.value = '';
    this.rvMakeTarget.value = '';
    this.rvModelTarget.value = '';
    this.rvMakeOtherTarget.value = '';

    this.towVehicleMakeTarget.value = '';
    this.towVehicleModelTarget.value = '';
    this.towVehicleMakeOtherTarget.value = '';

    this.equipmentOtherTarget.value = '';
  }

  rvFillData() {
    // fill in existing rv details

    if (userRv !== undefined) {
      if (userRv.equipment_type !== undefined) {
        if (userRv.equipment_type) this.equipmentTypeTarget.value = userRv.equipment_type;
        if (userRv.rv_make) this.rvMakeTarget.value = userRv.rv_make;
        this.rvModelTarget.value = userRv.rv_model;
        if (userRv.tow_vehicle_make) this.towVehicleMakeTarget.value = userRv.tow_vehicle_make;
        this.towVehicleModelTarget.value = userRv.tow_vehicle_model;

        this.equipmentOtherTarget.value = userRv.equipment_other;
        if (userRv.rv_make !== null && userRv.rv_make.match(/^Other/)) {
          this.rvMakeTarget.value = 'Other';
          this.rvMakeOtherTarget.value = userRv.rv_make.split(': ')[1]
          this.rvMakeOtherTarget.parentElement.classList.remove('hidden');
        }

        // unhide if there are existing values

        this.unhideRV(userRv);
        this.unhideTow(userRv);
        this.unhideOther(userRv);

        if (userRv.tow_vehicle_make !== null && userRv.tow_vehicle_make.match(/^Other/)) {
          this.towVehicleMakeTarget.value = 'Other';
          this.towVehicleMakeOtherTarget.value = userRv.tow_vehicle_make.split(': ')[1]
          this.towVehicleMakeOtherTarget.parentElement.classList.remove('hidden');
        }

        if (userRv.rv_length !== null) {
          this.rvLengthTarget.value = `${userRv.rv_length} feet`;

        }
      }
    }
  }
}