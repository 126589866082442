import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'uploadProfile', 'avatar' ]

  connect() {
    let photoError = sessionStorage.getItem("photoError")
    if (photoError) {
      this.profileAlert('Something went wrong with your file! Please upload an image smaller than 5MB.');
      window.scrollTo(0, 100);
      sessionStorage.removeItem("photoError")
    }
  }

  submitProfilePhotoError(e) {
    // error when profile photo is updated
    if (this.avatarTarget.files[0]) {
      if (this.avatarTarget.files[0].size > 5000000) {
        e.preventDefault();
        sessionStorage.setItem("photoError", "true")
        window.location.reload();
      }
    }
  }

  selectState(e) {
    const state_id = e.value;
  }

  profileAlert(message) {
    const alert = document.createElement("div");
    alert.innerHTML = '';
    alert.innerHTML = `<div class="alert alert-danger"><h5>${message}</h5></div>`;
    this.uploadProfileTarget.prepend(alert);
  }
}