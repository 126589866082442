import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'toggleEmail', 'changeEmailBtn']

  connect() {
  }

  toggleSwitch(e) {
    // update email toggle on the /user/edit/email page

    e.preventDefault();
    const input = e.target.parentElement.parentElement.querySelector('input');
    input.toggleAttribute('checked');
    const url = `/users/${input.dataset.user}/update_profile`;
    let form = new FormData();
    form.append(`user[${input.name}]`, input.checked)
    fetch(url, {
      method: 'POST',
      headers: { 'Accept': 'application/json'},
      body: form
    })
  }

  changeEmail(e) {
    // display hidden change email form /user/edit/email page

    e.preventDefault();
    this.changeEmailBtnTarget.classList.remove('hidden');
    e.target.classList.add('hidden');
  }
}