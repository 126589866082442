import { Controller } from "stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  async imageClicked(e) {
    if (window.admin && e.altKey) {
      e.preventDefault()
      e.stopPropagation()
      get(e.currentTarget.dataset.choosePhotoPath)
    }
  }
}